import React from "react";
import { IconType } from "react-icons";
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";

type Social = {
  href: string;
  title: string;
  icon: IconType;
};

const links: Social[] = [
  {
    href: "https://www.facebook.com/umich.chile",
    title: "Seguir en Facebook",
    icon: FaFacebook,
  },
  {
    href: "https://twitter.com/umichile",
    title: "Seguir en Twitter",
    icon: FaTwitter,
  },
  {
    href: "https://www.instagram.com/umichchile/",
    title: "Seguir en Instagram",
    icon: FaInstagram,
  },
  {
    href: "https://www.youtube.com/channel/UCKNkVyh6U1GI-SDYdbf3OZQ/videosSDYdbf3OZQ",
    title: "Seguir en Youtube",
    icon: FaYoutube,
  },
];
export default function SocialLinks() {
  return (
    <ul className="et_pb_module et_pb_social_media_follow et_pb_social_media_follow_0 clearfix  et_pb_text_align_center et_pb_bg_layout_light">
      {links.map((social) => (
        <Enlace social={social} />
      ))}
    </ul>
  );
}

const Enlace = ({ social }: { social: Social }) => (
  <li className="et_pb_social_media_follow_network_0 et_pb_social_icon et_pb_social_network_link  et-social-facebook et_pb_social_media_follow_network_0">
    <a href={social.href} title={social.title} target="_blank">
      {React.createElement(social.icon, { size: "2em" })}
    </a>
  </li>
);
